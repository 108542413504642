import { useLazyQuery } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { createContext, useEffect, useState } from 'react';
import {
  SIDENAV_MENU_CHATBOT,
  SIDENAV_MENUS
} from '../../Container/SidebarV1/constants';
import { getUserData } from '../../gql/queries/userData';
import { useHistory } from 'react-router-dom';
import { deleteCookie } from '..';
import { usePostHog } from 'posthog-js/react';

export const GlobalContext = createContext({});

const GlobalContextProvider = ({ children }) => {
  const [sideNavMenu, setSideNavMenu] = useState([]);
  const [leadData, setLeadData] = useState({});
  const [isZumaUser, setIsZumaUser] = useState(false);
  const [unit, setUnit] = useState({});
  const posthog = usePostHog();
  const history = useHistory();

  const themePreference = localStorage.getItem('theme_preference')
    ? JSON.parse(localStorage.getItem('theme_preference'))
    : false;
  const [darkMode, setDarkMode] = useState(themePreference);
  const [fetchUserData, { data }] = useLazyQuery(getUserData, {
    onCompleted(data) {
      const isZumaUser =
        data?.getUser?.role?.name !== 'CLIENT_ADMIN' &&
        data?.getUser?.role?.name !== 'CLIENT_USER';
      setIsZumaUser(isZumaUser);
      const pageAccessedByReload =
        (window.performance.navigation &&
          window.performance.navigation.type === 1) ||
        window.performance
          .getEntriesByType('navigation')
          .map(nav => nav.type)
          .includes('reload');

      if (!pageAccessedByReload && !isZumaUser) {
        history.replace(`/knowledge-base/${data?.getUser?.clients?.[0]._id}`);
      }
    },
    onError(error) {
      console.log('logging out', error);
      if (error.networkError && error.networkError.statusCode === 401) {
        deleteCookie('rb_access_token');
        window.location.href = process.env.REACT_APP_LOGIN_URL;
      }
    }
  });

  useEffect(() => {
    if (data?.getUser?.role?.permissions?.length) {
      console.log('data?.getUser?.role?.permissions', data?.getUser);
      const permissions = data?.getUser?.role?.permissions;
      const permissionNames = permissions.map(p => p.name);
      const resp = [];
      for (let key in SIDENAV_MENUS) {
        if (permissionNames?.includes(key)) {
          if (
            data?.getUser?.role?.name !== 'CLIENT_USER' &&
            data?.getUser?.role?.name !== 'CLIENT_ADMIN' &&
            (key === 'KNOWLEDGE_BASE_UPDATE' || key === 'KNOWLEDGE_BASE_VIEW')
          ) {
            continue;
          }
          resp.push(SIDENAV_MENUS[key]);
        }
      }
      if (data?.getUser?.role?.name === 'SUPER_ADMIN') {
        resp.push(SIDENAV_MENU_CHATBOT);
      }

      setSideNavMenu(resp);
    }

    if (
      data?.getUser?.role?.name === 'CLIENT_USER' ||
      data?.getUser?.role?.name === 'CLIENT_ADMIN'
    ) {
      setIsZumaUser(false);
    } else {
      setIsZumaUser(true);
    }
  }, [data]);

  useEffect(() => {
    localStorage.setItem('theme_preference', JSON.stringify(darkMode));
  }, []);

  const {
    _id,
    name,
    mightyCall,
    email,
    userAccess,
    uid,
    role,
    trainedClients,
    clients,
    assignedClients,
    status
  } = data?.getUser || {};
  const userData = {
    _id,
    name,
    trainedClients,
    clients,
    assignedClients,
    mightyCall,
    user_id: uid,
    email,
    tenantId: userAccess?.clients?.trained || [],
    userAccess: userAccess,
    role,
    status
  };

  process.env.NODE_ENV === 'production' &&
    userData?.email &&
    Sentry.setUser({
      email: userData?.email,
      username: userData?.name,
      id: userData?._id
    });

  process.env.NODE_ENV === 'production' &&
    userData?.email &&
    posthog?.identify(userData?._id, {
      email: userData?.email
    });

  const updateLeadData = data => {
    setLeadData(data);
  };

  const toggleTheme = () => {
    setDarkMode(prevState => {
      localStorage.setItem('theme_preference', JSON.stringify(!prevState));
      return !prevState;
    });
  };

  const value = {
    sideNavMenu,
    sideNavRoutes: sideNavMenu.map(menu => menu.access),
    userData,
    leadData,
    fetchUserData,
    isZumaUser,
    updateLeadData,
    darkMode,
    toggleTheme,
    unit,
    setUnit
  };

  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  );
};

export default GlobalContextProvider;
