import React, { useState, useEffect } from 'react';
import {
  Switch,
  Input,
  InputNumber,
  Select,
  Typography,
  Divider,
  ColorPicker,
  message
} from 'antd';
import { useChatbotSettingsContext } from '../../../utils/ContextProviders/ChatbotSettingsContextProvider';

const { Text } = Typography;
const { Option } = Select;

const ChatbotSettingsForm = () => {
  const {
    settingsFormData,
    setSettingsFormData,
    usePresetSettings,
    view,
    presetSettings,
    handlePublishUpdates
  } = useChatbotSettingsContext();
  const { communityId } = view;
  const [fetchConfig, setFetchConfig] = useState(1);

  useEffect(() => {
    if (communityId) {
      if (usePresetSettings) {
        setSettingsFormData(presetSettings);
      } else setFetchConfig(prev => prev + 1);
    }
  }, [usePresetSettings]);

  useEffect(() => {
    if (!communityId) return;
    const fetchConfigDetails = async () => {
      try {
        const response = await fetch(
          `https://kelsey.getzuma.com/v1/zuma-chatbot/fetchConfigDetails?community_id=${communityId}`
        );
        const data = await response.json();

        if (response.ok && data.session_data) {
          const sessionData = data.session_data;

          // Map API response to settingsFormData
          const mappedSettings = {
            contactUsEnabled: sessionData.widgets_to_show.includes('textus'),
            pricingEnabled: sessionData.widgets_to_show.includes('pna'),
            scheduleTourEnabled:
              sessionData.widgets_to_show.includes('booktour'),
            primaryColor: sessionData.header_color || '#1677ff',
            chatIconAlignment:
              sessionData.left_position !== null ? 'Left' : 'Right',
            leftPadding:
              sessionData.left_position !== null
                ? sessionData.left_position + 20
                : null,
            bottomPadding: sessionData.bottom_position + 20,
            greetingMessage:
              sessionData.first_message ||
              'Hi there! How can I help you today?',
            rightPadding:
              sessionData.right_position !== null
                ? sessionData.right_position + 20
                : null,
            isTookBookingAllowed: sessionData.can_book_tour_flag
          };

          setSettingsFormData(mappedSettings);
        } else {
          setSettingsFormData(presetSettings);
          console.log('calling handlePublishUpdates');
          handlePublishUpdates(presetSettings);
          console.error('Failed to load configuration data.');
        }
      } catch (error) {
        setSettingsFormData(presetSettings);
        console.error('Error fetching config details:', error);
        message.error('An error occurred while fetching configuration data.');
      }
    };

    fetchConfigDetails();
  }, [communityId, setSettingsFormData, fetchConfig]);

  const handleChange = (field, value) => {
    if (usePresetSettings) return;
    setSettingsFormData(prevState => ({
      ...prevState,
      [field]: value
    }));
  };

  return (
    <div style={{ width: '100%' }}>
      {/* Features Section */}
      <div style={{ marginBottom: '24px' }}>
        <Text
          strong
          style={{ fontSize: '16px', display: 'block', marginBottom: '8px' }}
        >
          Features
        </Text>
        <div style={{ marginBottom: '8px' }}>
          <Switch
            checked={settingsFormData.contactUsEnabled}
            onChange={checked => handleChange('contactUsEnabled', checked)}
            style={{ marginRight: '8px' }}
            disabled={usePresetSettings}
          />
          <Text>Contact (or Text Us)</Text>
        </div>
        <div style={{ marginBottom: '8px' }}>
          <Switch
            checked={settingsFormData.pricingEnabled}
            onChange={checked => handleChange('pricingEnabled', checked)}
            style={{ marginRight: '8px' }}
            disabled={usePresetSettings}
          />
          <Text>Pricing & Availability (PNA)</Text>
        </div>
        <div style={{ marginBottom: '8px' }}>
          <Switch
            checked={settingsFormData.scheduleTourEnabled}
            onChange={checked => handleChange('scheduleTourEnabled', checked)}
            style={{ marginRight: '8px' }}
            disabled={usePresetSettings}
          />
          <Text>Schedule Tour</Text>
        </div>
        <div>
          <Switch
            checked={settingsFormData.isTookBookingAllowed}
            onChange={checked => handleChange('isTookBookingAllowed', checked)}
            style={{ marginRight: '8px' }}
            disabled={usePresetSettings}
          />
          <Text>Schedule Tour (via Chatbot)</Text>
        </div>
      </div>

      <Divider />

      {/* Chatbot Appearance Section */}
      <Text
        strong
        style={{ fontSize: '16px', display: 'block', marginBottom: '8px' }}
      >
        Chatbot Appearance
      </Text>
      <div
        style={{
          marginBottom: '16px',
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
          width: 'fit-content'
        }}
      >
        <Text>Primary Color</Text>
        <ColorPicker
          value={settingsFormData.primaryColor}
          showText
          onChange={color => handleChange('primaryColor', color.toHexString())}
          disabled={usePresetSettings}
        />
      </div>

      <div style={{ marginBottom: '16px', width: '160px' }}>
        <Text>Chat Icon Alignment</Text>
        <Select
          value={settingsFormData.chatIconAlignment}
          onChange={value => handleChange('chatIconAlignment', value)}
          style={{ width: '100%', marginTop: '8px' }}
          disabled={usePresetSettings}
        >
          <Option value="Left">Left</Option>
          <Option value="Right">Right</Option>
        </Select>
      </div>

      {/* Conditional Padding */}
      <div style={{ marginBottom: '16px', width: '160px' }}>
        <Text>
          {settingsFormData.chatIconAlignment === 'Left'
            ? 'Left Padding (Pixel)'
            : 'Right Padding (Pixel)'}
        </Text>
        <InputNumber
          value={
            settingsFormData.chatIconAlignment === 'Left'
              ? settingsFormData.leftPadding
              : settingsFormData.rightPadding
          }
          onChange={value =>
            handleChange(
              settingsFormData.chatIconAlignment === 'Left'
                ? 'leftPadding'
                : 'rightPadding',
              value
            )
          }
          style={{ width: '100%', marginTop: '8px' }}
          disabled={usePresetSettings}
        />
      </div>

      <div style={{ marginBottom: '16px', width: '160px' }}>
        <Text>Bottom Padding (Pixel)</Text>
        <InputNumber
          value={settingsFormData.bottomPadding}
          onChange={value => handleChange('bottomPadding', value)}
          style={{ width: '100%', marginTop: '8px' }}
          disabled={usePresetSettings}
        />
      </div>

      <div style={{ marginBottom: '16px', width: '345px' }}>
        <Text>Greeting Message</Text>
        <Input.TextArea
          value={settingsFormData.greetingMessage}
          onChange={e => handleChange('greetingMessage', e.target.value)}
          rows={3}
          style={{ marginTop: '8px' }}
          disabled={usePresetSettings}
        />
      </div>
    </div>
  );
};

export default ChatbotSettingsForm;
