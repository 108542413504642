import React from 'react';
import Layout from '../../Component/layout';
import { ChatbotSettingsContextProvider } from '../../utils/ContextProviders/ChatbotSettingsContextProvider';
import ChatbotSettingsComponent from '../../Component/ChatbotSettingsComponent';
import ChatbotSettingsHeader from '../../Component/ChatbotSettingsComponent/ChabotSettingsHeader';
const ChatbotSettings = props => {
  return (
    <Layout history={props.history}>
      <ChatbotSettingsContextProvider>
        <div style={{ background: '#fff' }}>
          <ChatbotSettingsHeader />
          <ChatbotSettingsComponent />
        </div>
      </ChatbotSettingsContextProvider>
    </Layout>
  );
};

export default ChatbotSettings;
