import React, { useContext, useState } from 'react';
import { Menu, Select, message } from 'antd';
import { useLazyQuery } from '@apollo/client';
import { VIEW_TYPES } from '../utils';
import { getApartmentsByTenantIds } from '../../../gql/queries/apartmentByTenant';
import { GlobalContext } from '../../../utils/ContextProviders/GlobalContextProvider';
import { useChatbotSettingsContext } from '../../../utils/ContextProviders/ChatbotSettingsContextProvider';

const { Option } = Select;

const CommunitiesList = () => {
  const { setView } = useChatbotSettingsContext();
  const { userData } = useContext(GlobalContext);
  const tenantsByUser = userData?.clients;
  const [selectedTenant, setSelectedTenant] = useState(null);
  const [communities, setCommunities] = useState([]);
  const [selectedItem, setSelectedItem] = useState('preset');

  // Lazy query to fetch communities
  const [getCommunitiesByClients, { loading }] = useLazyQuery(
    getApartmentsByTenantIds,
    {
      onCompleted: data => {
        setCommunities(data?.apartmentsByTenantIds || []);
      },
      onError: error => {
        message.error('Failed to fetch communities.');
        console.error('GraphQL Error:', error);
      }
    }
  );

  // Handle tenant selection
  const handleTenantChange = tenantId => {
    setSelectedTenant(tenantId);
    if (tenantId) {
      getCommunitiesByClients({
        variables: { tenantIds: [tenantId] }
      });
    } else {
      setCommunities([]);
    }
  };

  // Handle menu selection
  const handleSelection = e => {
    const key = e.key;
    setSelectedItem(key);

    if (key === 'preset') {
      setView({ view: VIEW_TYPES.PRESET_CHATBOT_SETTINGS });
    } else {
      const selectedCommunity = communities.find(
        community => community._id === key
      );
      if (selectedCommunity) {
        setView({
          view: VIEW_TYPES.COMMUNITY_CHATBOT_SETTINGS,
          communityId: selectedCommunity._id,
          tenantId: selectedTenant,
          communityName: selectedCommunity.name
        });
      }
    }
  };

  // Generate menu items with custom tenant dropdown
  const menuItems = [
    {
      key: 'tenants',
      type: 'group',
      children: [
        {
          key: 'tenant-dropdown',
          style: { paddingLeft: 0 },
          label: (
            <Select
              placeholder="Select Clients..."
              value={selectedTenant}
              onChange={handleTenantChange}
              style={{ width: '100%', paddingLeft: 0 }}
              allowClear
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={tenantsByUser.map(tenant => ({
                value: tenant._id,
                label: tenant.name
              }))}
            />
          )
        }
      ]
    },
    {
      key: 'preset-group',
      label: 'Preset',
      type: 'group',
      children: [
        {
          key: 'preset',
          label: 'Preset Chatbot'
        }
      ]
    },
    {
      key: 'communties',
      type: 'group',
      label: 'Communities',
      children: communities.map(community => ({
        key: community._id,
        label: community.name
      })),
      style: { height: 'calc(100vh - 380px)', overflowY: 'auto' }
    }
  ];

  return (
    <div>
      {/* Communities Menu with Tenant Dropdown */}
      <Menu
        onClick={handleSelection}
        style={{ width: 256, borderInlineEnd: 'none' }}
        selectedKeys={[selectedItem]}
        mode="inline"
        items={menuItems}
      />
    </div>
  );
};

export default CommunitiesList;
