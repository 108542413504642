import React from 'react';
import CommunitiesList from './CommunitiesList';
import { Divider, Flex, Switch, Typography } from 'antd';

import ChatbotSettingsForm from './ChatbotSettingsForm';
import { useChatbotSettingsContext } from '../../utils/ContextProviders/ChatbotSettingsContextProvider';
import { VIEW_TYPES } from './utils';
import ChatbotScript from './ChatbotScript';
const { Title, Text } = Typography;
const ChatbotSettingsComponent = () => {
  const { communities, view, usePresetSettings, setUsePresetSettings } =
    useChatbotSettingsContext();
  return (
    <Flex
      gap={24}
      style={{
        backgroundColor: 'white',
        padding: '20px',
        height: 'calc(100vh - 200px)'
      }}
    >
      <CommunitiesList />
      <Divider type="vertical" style={{ height: '100%' }} />
      <Flex vertical gap={24} style={{ flex: 1, overflowY: 'auto' }}>
        {view.view === VIEW_TYPES.PRESET_CHATBOT_SETTINGS ? (
          <Title style={{ marginTop: 0 }} level={3}>
            Preset Chatbot Settings
          </Title>
        ) : (
          <Title style={{ marginTop: 0 }} level={3}>
            {view.communityName} Chatbot Settings
          </Title>
        )}
        {view.view === VIEW_TYPES.COMMUNITY_CHATBOT_SETTINGS && (
          <div style={{ marginBottom: '8px' }}>
            <Switch
              checked={usePresetSettings}
              onChange={checked => setUsePresetSettings(checked)}
              style={{ marginRight: '8px' }}
            />
            <Text>Use Preset Settings</Text>
          </div>
        )}
        <Divider style={{ margin: 0 }} />
        <ChatbotSettingsForm />
        {view.view === VIEW_TYPES.COMMUNITY_CHATBOT_SETTINGS && (
          <ChatbotScript />
        )}
      </Flex>
    </Flex>
  );
};
export default ChatbotSettingsComponent;
