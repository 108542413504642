import React from 'react';
import { Button, Row, Col } from 'antd';
import { useChatbotSettingsContext } from '../../../utils/ContextProviders/ChatbotSettingsContextProvider';
import { VIEW_TYPES } from '../utils';

const ChatbotSettingsHeader = () => {
  const {
    communities,
    view,
    usePresetSettings,
    setUsePresetSettings,
    settingsFormData,
    handlePublishUpdates
  } = useChatbotSettingsContext();

  return (
    <Row justify="space-between" align="middle" style={{ padding: '16px' }}>
      {/* Left Title */}
      <Col>
        <h1 style={{ marginTop: 5, marginBottom: 5 }}>Chatbot Settings</h1>
      </Col>
      {/* Right Buttons */}
      {view.view === VIEW_TYPES.COMMUNITY_CHATBOT_SETTINGS && (
        <Col>
          <div style={{ display: 'flex', gap: '8px' }}>
            <Button type="default">Cancel</Button>
            <Button
              type="primary"
              onClick={() => handlePublishUpdates(settingsFormData)}
            >
              Publish Updates
            </Button>
          </div>
        </Col>
      )}
    </Row>
  );
};

export default ChatbotSettingsHeader;
