import React from 'react';
import { Button, message, Typography } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { Controlled as CodeMirror } from 'react-codemirror2';
import { useChatbotSettingsContext } from '../../../utils/ContextProviders/ChatbotSettingsContextProvider';
const { Text } = Typography;

const ChatbotScript = () => {
  const { view } = useChatbotSettingsContext();
  const apartmentId = view.communityId;
  const tenantId = view.tenantId;

  const script = `
<script async>
(function () {
    const loadWidget = () => {
        const config = {
            apartmentId: '${apartmentId}', 
            tenantId: '${tenantId}', 
        };

        fetchConfig(config).then(apiConfig => {
            const finalConfig = { ...config, ...apiConfig };
            const allowedExtraWidgets = ['pna', 'textus', 'booktour'];
            let baseHeight = '110px';
            const baseWidth = '110px';

            const getWidgetDimensions = widgetsToShow => {
                if (!widgetsToShow || widgetsToShow.length === 0) {
                    return {
                        collapsedHeight: baseHeight,
                        collapsedWidth: baseWidth
                    };
                } else {
                    const collapsedHeight = \`\${110 + widgetsToShow.length * 55}px\`;
                    const collapsedWidth = '180px';
                    return { collapsedHeight, collapsedWidth };
                }
            };

            const { collapsedHeight, collapsedWidth } = getWidgetDimensions(finalConfig.widgetsToShow);

            const expandedWidth = '440px';
            const expandedHeight = '778px';
            const expandedHeightForSmallHeightScreen = '658px';
            const modalWidth = '100%';
            const modalHeight = '100%';

            const widget = document.createElement('div');
            const widgetStyle = widget.style;

            widgetStyle.display = 'none';
            widgetStyle.boxSizing = 'border-box';
            widgetStyle.width = collapsedWidth;
            widgetStyle.height = collapsedHeight;
            widgetStyle.position = 'absolute';
            widgetStyle.bottom = \`\${finalConfig.bottomPosition ?? 0}px\`;

            if (finalConfig.leftPosition !== null && finalConfig.leftPosition !== undefined) {
                widgetStyle.left = \`\${finalConfig.leftPosition ?? 0}px\`;
            } else {
                widgetStyle.right = \`\${finalConfig.rightPosition ?? 0}px\`;
            }

            widgetStyle.zIndex = '999999';

            const iframe = document.createElement('iframe');
            iframe.id = 'chatbot-iframe';
            iframe.sandbox = 'allow-scripts allow-same-origin allow-forms allow-popups';

            const iframeStyle = iframe.style;
            iframeStyle.boxSizing = 'border-box';
            iframeStyle.position = 'fixed';
            iframeStyle.bottom = \`\${finalConfig.bottomPosition ?? 0}px\`;

            if (finalConfig.leftPosition !== null && finalConfig.leftPosition !== undefined) {
                iframeStyle.left = \`\${finalConfig.leftPosition ?? 0}px\`;
            } else {
                iframeStyle.right = \`\${finalConfig.rightPosition ?? 0}px\`;
            }

            iframeStyle.width = collapsedWidth;
            iframeStyle.height = collapsedHeight;
            iframeStyle.border = '0';
            iframeStyle.margin = '0';
            iframeStyle.padding = '0';

            widget.appendChild(iframe);

            iframe.addEventListener('load', () => {
                setTimeout(() => {
                    try {
                        iframe.contentWindow.postMessage(finalConfig, iframe.src);
                    } catch (error) {
                        console.error('Error sending message to iframe:', error);
                    }
                    widgetStyle.display = 'block';
                }, 1000);

                window.addEventListener('message', event => {
                    if (event.data === 'open') {
                        widgetStyle.width = expandedWidth;
                        widgetStyle.height = expandedHeight;
                        iframeStyle.width = expandedWidth;
                        iframeStyle.height = expandedHeight;
                    } else if (event.data === 'smallheightScreenOpen') {
                        widgetStyle.width = expandedWidth;
                        widgetStyle.height = expandedHeightForSmallHeightScreen;
                        iframeStyle.width = expandedWidth;
                        iframeStyle.height = expandedHeightForSmallHeightScreen;
                    } else if (event.data === 'close') {
                        widgetStyle.width = collapsedWidth;
                        widgetStyle.height = collapsedHeight;
                        iframeStyle.width = collapsedWidth;
                        iframeStyle.height = collapsedHeight;
                    } else if (event.data === 'modalopen' || event.data === 'mobileopen') {
                        widgetStyle.width = modalWidth;
                        widgetStyle.height = modalHeight;
                        iframeStyle.width = modalWidth;
                        iframeStyle.height = modalHeight;
                        widgetStyle.bottom = '0px';
                        iframeStyle.bottom = '0px';

                        if (finalConfig.leftPosition !== null && finalConfig.leftPosition !== undefined) {
                            iframeStyle.left = '0px';
                            widgetStyle.left = '0px';
                        } else {
                            iframeStyle.right = '0px';
                            widgetStyle.right = '0px';
                        }
                    } else if (event.data === 'mobileclose') {
                        widgetStyle.width = collapsedWidth;
                        widgetStyle.height = collapsedHeight;
                        iframeStyle.width = collapsedWidth;
                        iframeStyle.height = collapsedHeight;
                        widgetStyle.bottom = \`\${finalConfig.bottomPosition ?? 0}px\`;
                        iframeStyle.bottom = \`\${finalConfig.bottomPosition ?? 0}px\`;

                        if (finalConfig.leftPosition !== null && finalConfig.leftPosition !== undefined) {
                            iframeStyle.left = \`\${finalConfig.leftPosition ?? 0}px\`;
                            widgetStyle.left = \`\${finalConfig.leftPosition ?? 0}px\`;
                        } else {
                            iframeStyle.right = \`\${finalConfig.rightPosition ?? 0}px\`;
                            widgetStyle.right = \`\${finalConfig.rightPosition ?? 0}px\`;
                        }
                    }
                });
            });

            const widgetUrl = 'https://prod.chatbot.getzuma.com/';
            iframe.src = widgetUrl;

            document.body.appendChild(widget);
        });
    };

    const fetchConfig = async config => {
        try {
            const response = await fetch(
                \`https://kelsey.getzuma.com/v1/zuma-chatbot/fetchConfigDetails?community_id=\${config.apartmentId}\`
            );
            if (response.ok) {
                const data = await response.json();
                if (data.status === 200) {
                    return {
                        headerColor: data?.session_data?.header_color,
                        bottomPosition: data?.session_data?.bottom_position,
                        isTourBookingAllowed: data?.session_data?.can_book_tour_flag,
                        leftPosition: data?.session_data?.left_position,
                        rightPosition: data?.session_data?.right_position,
                        widgetsToShow: data?.session_data?.widgets_to_show,
                        tenantId: data?.session_data?.tenant_id,
                        apartmentId: data?.session_data?.community_id,
                        greetingMessage: data?.session_data?.first_message
                    };
                }
                throw new Error('Failed to fetch configuration');
            }
            throw new Error('Failed to fetch configuration');
        } catch (error) {
            console.error('Error fetching config:', error);
            return {};
        }
    };

    if (document.readyState === 'complete') {
        loadWidget();
    } else {
        document.addEventListener('readystatechange', () => {
            if (document.readyState === 'complete') {
                loadWidget();
            }
        });
    }
})();
</script>
`;

  const handleCopy = () => {
    navigator.clipboard.writeText(script).then(() => {});
    message.success('Copied!');
  };

  return (
    <div
      style={{
        padding: '16px',
        border: '1px solid #e0e0e0',
        borderRadius: '8px'
      }}
    >
      <Text
        strong
        style={{ display: 'block', marginBottom: '16px', fontSize: '16px' }}
      >
        Chatbot Script
      </Text>
      <Text style={{ marginBottom: '16px', display: 'block' }}>
        Copy the script below, paste it into your website, and watch your
        chatbot go live in no time.
      </Text>
      <div style={{ marginBottom: '16px' }}>
        <CodeMirror
          value={script.trim()}
          options={{
            mode: 'javascript', // Highlight JavaScript syntax
            theme: 'material', // Use the Material theme
            lineNumbers: true, // Show line numbers
            readOnly: true // Make the editor read-only
          }}
        />
      </div>
      <Button type="primary" icon={<CopyOutlined />} onClick={handleCopy}>
        Copy Script
      </Button>
    </div>
  );
};

export default ChatbotScript;
