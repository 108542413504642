import React, { createContext, useContext, useEffect, useState } from 'react';
import {
  API_ENDPOINTS,
  VIEW_TYPES
} from '../../Component/ChatbotSettingsComponent/utils';
import { message } from 'antd';

// Create the context
const ChatbotSettingsContext = createContext();

// Provider component
export const ChatbotSettingsContextProvider = ({ children }) => {
  const [view, setView] = useState({
    view: VIEW_TYPES.PRESET_CHATBOT_SETTINGS
  });
  const presetSettings = {
    contactUsEnabled: true,
    pricingEnabled: true,
    scheduleTourEnabled: true,
    primaryColor: '#1677ff',
    chatIconAlignment: 'Right',
    rightPadding: 20,
    bottomPadding: 20,
    greetingMessage: 'Hi there! How can I help you today?',
    leftPadding: null,
    isTookBookingAllowed: true
  };
  const [settingsFormData, setSettingsFormData] = useState(presetSettings);
  const [usePresetSettings, setUsePresetSettings] = useState(false);

  const handlePublishUpdates = async settingsFormDataParam => {
    const settingsFormDataTemp = settingsFormDataParam || settingsFormData;

    // Dynamically construct the `widgets_to_show` array based on `settingsFormData`
    const widgets_to_show = [];
    if (settingsFormDataTemp.contactUsEnabled) widgets_to_show.push('textus');
    if (settingsFormDataTemp.pricingEnabled) widgets_to_show.push('pna');
    if (settingsFormDataTemp.scheduleTourEnabled)
      widgets_to_show.push('booktour');

    const payload = {
      communityData: {
        community_id: view?.communityId,
        tenant_id: view?.tenantId,
        can_book_tour: settingsFormDataTemp.isTookBookingAllowed
      },
      frontendData: {
        header_color: settingsFormDataTemp.primaryColor,
        bottom_position: settingsFormDataTemp.bottomPadding - 20,
        right_position:
          settingsFormDataTemp.chatIconAlignment === 'Right'
            ? settingsFormDataTemp.rightPadding - 20
            : null,
        left_position:
          settingsFormDataTemp.chatIconAlignment === 'Left'
            ? settingsFormDataTemp.leftPadding - 20
            : null,
        widgets_to_show,
        first_message: settingsFormDataTemp.greetingMessage
      }
    };

    try {
      const response = await fetch(API_ENDPOINTS.SAVE_CONFIG_DETAILS, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      });

      if (response.ok) {
        const data = await response.json();
        message.success('Settings saved successfully!');
        console.log('API Response:', data);
      } else {
        const errorData = await response.json();
        message.error(`Failed to save settings: ${errorData.message}`);
        console.error('API Error:', errorData);
      }
    } catch (error) {
      message.error('An error occurred while saving settings.');
      console.error('Fetch Error:', error);
    }
  };

  return (
    <ChatbotSettingsContext.Provider
      value={{
        setView,
        view,
        settingsFormData,
        setSettingsFormData,
        usePresetSettings,
        setUsePresetSettings,
        presetSettings,
        handlePublishUpdates
      }}
    >
      {children}
    </ChatbotSettingsContext.Provider>
  );
};

// Custom hook to use the context
export const useChatbotSettingsContext = () => {
  const context = useContext(ChatbotSettingsContext);
  if (!context) {
    throw new Error(
      'useChatbotSettingsContext must be used within a ChatbotSettingsContextProvider'
    );
  }
  return context;
};
