import React, { useContext, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { connect, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  AppBar,
  FormControl,
  InputAdornment,
  TextField,
  Toolbar
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useLocation } from 'react-router-dom';
import {
  ClearGlobalSearch,
  GetGlobalSearchData
} from '../../Redux/Actions/GlobalSearch';
import './Topbar.scss';
import { socketEmitter } from '../../utils/socket';
import { GlobalContext } from '../../utils/ContextProviders/GlobalContextProvider';
import {
  AppBarContainer,
  PageName
} from '../../Container/InboxV2/styles.inboxv2';
import { useTheme } from 'styled-components';
import Icon from '../../globalcomponents/Icon';
import Avatar from '../../globalcomponents/Avatar';
import UserControls from './UserControls';
import { updateAgentStatus } from '../../gql/mutations/updateUser';
import { getUserStatus } from '../../gql/queries/userData';
import { deleteCookie } from '../../utils';
import { USER_STATUS_LIST } from './constants';

const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1,
    position: 'fixed',
    width: 'calc(100% - 65px)',
    zIndex: '99',
    '& .MuiAppBar-colorPrimary': {
      backgroundColor: '#F8F8F8 !important'
    },
    '& .MuiPaper-elevation4': {
      boxShadow: 'none'
    },
    '& .MuiToolbar-root.MuiToolbar-regular': {
      backgroundColor: '#F8F8F8 !important'
    }
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block'
    }
  },
  search: {
    position: 'relative',
    borderRadius: '100px',
    backgroundColor: '#f2f2f2',
    marginRight: theme.spacing(2),
    marginLeft: 0,

    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto'
    },
    color: '#aaaaaa'
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  inputRoot: {
    color: 'inherit'
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch'
    }
  },
  sectionDesktop: {
    display: 'flex',
    width: '300px',
    marginLeft: '10px',
    justifyContent: 'flex-end',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    }
  },
  sectionMobile: {
    display: 'flex'
  },
  badge: {
    right: -3,
    top: 5,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
    margin: '-3px',
    textTransform: 'capitalize'
  }
}));

const PrimaryTopBar = props => {
  const theme = useTheme();
  const Location = props.history && props.history.location.pathname;
  const { userData, leadData, isZumaUser, toggleTheme } =
    useContext(GlobalContext);

  const [PlaceObj, setPlaceObj] = useState([{ name: '' }]);
  const [searchVal, setSearchVal] = useState('');
  const [statusColor, setStatusColor] = useState('#808080');
  const [lastClickedStatus, setLastClickedStatus] = useState('');
  const [isFetchingUserStatus, setIsFetchingUserStatus] = useState(false);

  const [userControlsWidgetVisibility, setUserControlsWidgetVisibility] =
    useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();

  const agentId = userData?._id;
  const agentAuthId = userData?.user_id;

  const redirectToLoginPage = () => {
    window.location.href = 'https://login.getzuma.com';
  };

  const redirectToUserProfilePage = () => {
    props.history.push('/agentProfile');
  };

  const toggleUserControlsWidgetVisibility = () => {
    setUserControlsWidgetVisibility(prevState => !prevState);
  };

  const avatarClickHandler = e => {
    e.preventDefault();
    toggleUserControlsWidgetVisibility();
  };

  const toggleUserStatusHandler = (e, updatedStatus) => {
    e.preventDefault();
    setLastClickedStatus(updatedStatus);
    updateUserStatus({
      variables: {
        agentId: agentId,
        status: updatedStatus
      }
    });
  };

  const signOutClickHandler = e => {
    e.preventDefault();
    socketEmitter('leave-room', agentAuthId);
    toggleUserStatusHandler(e, 'offline');
    redirectToLoginPage();
    deleteCookie('rb_access_token');
  };

  const editProfileClickHandler = e => {
    e.preventDefault();
    redirectToUserProfilePage();
  };

  const createSocketRoomAndUpdateStatus = (updatedStatus, agentId) => {
    setAgentStatus(updatedStatus);
    const userStatusIndicatorColor = USER_STATUS_LIST?.find(
      ({ key }) => key === updatedStatus
    )?.indicatorColor;
    setStatusColor(userStatusIndicatorColor);
    socketEmitter('create-agent-room', { userId: agentId });
  };

  const locationFunc = () => {
    if (Location === '/calendar') {
      return `Calendar`;
    } else if (Location === '/ctt') {
      return `CTT`;
    } else if (Location === '/leads') {
      return `Leads`;
    } else if (Location === '/') {
      return `Inbox`;
    } else if (Location === '/communities') {
      return `Communities`;
    } else if (Location === '/toxicity') {
      return `Toxicity`;
    } else if (Location === '/admin') {
      return `Self Serve`;
    } else if (Location === '/new-workflow') {
      return `Workflow master view`;
    } else if (Location === '/workflow' && props.sequenceAdd === false) {
      return `Templates master view`;
    } else if (Location === '/workflow' && props.sequenceAdd === true) {
      return `Sequence`;
    } else if (location.pathname.includes('/workflow/sequence/')) {
      return `Sequence`;
    } else if (location?.pathname?.includes('/inbox/searchLead/')) {
      return `Inbox / ${leadData?.name}`;
    } else if (location?.pathname?.includes('/knowledge-base')) {
      if (!isZumaUser) {
        return userData?.clients?.[0]?.name;
      }
      return `Knowledge Base`;
    } else if (location?.pathname?.includes('/chatbot-settings')) return ``;
    else {
      return `Property`;
    }
  };

  const [agentStatus, setAgentStatus] = useState('offline');
  const [username, setUsername] = useState('');

  const searchGlobalData = val => {
    if (val.length >= 3) {
      setSearchVal(val);
      dispatch(GetGlobalSearchData(val));
    }
  };

  // graphql query to fetch latest user status
  const { refetch } = useQuery(getUserStatus, {
    skip: true
  });

  // graphql mutation to update user status - online, offline and onbreak
  const [updateUserStatus, { loading: updatingUserStatus }] = useMutation(
    updateAgentStatus,
    {
      refetchQueries: [{ query: getUserStatus }],
      onCompleted: async () => {
        setIsFetchingUserStatus(true);
        const { data } = await refetch();
        const { getUser } = data;
        setAgentStatus(getUser?.status);
        setIsFetchingUserStatus(false);
      }
    }
  );

  useEffect(() => {
    dispatch(ClearGlobalSearch(''));
  }, [Location]);
  useEffect(() => {
    if (props?.GlobalData?.data && props?.GlobalData?.data?.length > 0) {
      setPlaceObj(props?.GlobalData && props?.GlobalData?.data);
    }
  }, [props?.GlobalData]);

  const AutoCompHandleChange = (event, data) => {
    if (data.type === 'leads') {
      props.history.push({
        pathname: `/inbox/searchLead/${data._id}`,
        state: { from: location.pathname }
      });
    }
    if (data.type === 'property') {
      props.history.push(`/property/edit/${data._id}`);
    }
  };

  const options = PlaceObj.map(option => {
    if (option.type !== undefined) {
      const SortBy = option.type.toUpperCase();
      const name = option.phone
        ? option.phone + ' ' + `${option.name}`
        : ' ' + `${option.name}`;
      let newObj = { ...option, name };
      if (!isNaN(searchVal)) {
        return { SortBy: SortBy, ...newObj };
      } else {
        return {
          SortBy: SortBy,
          ...option
        };
      }
    } else {
      return { name: '' };
    }
  });

  useEffect(() => {
    setUsername(userData?.name);
    createSocketRoomAndUpdateStatus(userData?.status, agentAuthId);
  }, [userData, agentAuthId]);

  return (
    <AppBarContainer>
      <AppBar position="static" style={{ backgroundColor: '#F8F8F8' }}>
        <Toolbar
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '0 10px'
          }}
        >
          <PageName>{locationFunc()}</PageName>
          <FormControl variant="outlined">
            {isZumaUser && (
              <Autocomplete
                freeSolo
                id="GlobalSearch"
                disableClearable={true}
                autoHighlight={true}
                options={
                  options ||
                  [].sort((a, b) => -b.SortBy.localeCompare(a.SortBy))
                }
                groupBy={option => option.SortBy}
                getOptionLabel={option => option.name}
                filterOptions={options => options.filter(opt => opt.name)}
                onChange={(event, value) => AutoCompHandleChange(event, value)}
                renderInput={params => (
                  <TextField
                    {...params}
                    onChange={e => searchGlobalData(e.target.value)}
                    placeholder="Search anything"
                    variant="filled"
                    InputProps={{
                      ...params.InputProps,
                      type: 'search',
                      style: {
                        borderRadius: '4px',
                        padding: '5px 4px',
                        backgroundColor: theme.background.secondary,
                        color:
                          searchVal === ''
                            ? theme.text.placeholder
                            : theme.text.primary,
                        fontSize: '14px',
                        width: 400,
                        boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.04)'
                      },
                      disableUnderline: true,
                      startAdornment: (
                        <InputAdornment
                          style={{
                            paddingLeft: '10px',
                            alignSelf: 'center',
                            paddingBottom: '17px'
                          }}
                        >
                          <Icon
                            src={`search-${theme.state}.svg`}
                            imgStyle={{ height: '18px', width: '15px' }}
                          />
                        </InputAdornment>
                      )
                    }}
                  />
                )}
              />
            )}
          </FormControl>
          <div className={classes.sectionDesktop}>
            <div
              style={{
                marginRight: '0px',
                borderRadius: '8px',
                padding: '4px 0'
              }}
            >
              <div className="flex" style={{ position: 'relative' }}>
                {isZumaUser && (
                  <div
                    data-testid="status-indicator"
                    data-status={agentStatus}
                    className="agent-status"
                    style={{
                      backgroundColor: statusColor,
                      borderColor: theme?.background?.universal
                    }}
                  />
                )}
                <Avatar
                  size="lg"
                  clickable
                  onClick={avatarClickHandler}
                  image="https://assets.getzuma.com/images/dummy-avatar.svg"
                />
                <div
                  className={`user-controls-container ${
                    userControlsWidgetVisibility ? 'open' : ''
                  }`}
                >
                  <UserControls
                    currentUserName={username}
                    currentUserStatus={agentStatus}
                    currentTheme={theme?.state}
                    onToggleTheme={toggleTheme}
                    onToggleUserStatus={toggleUserStatusHandler}
                    onSignOut={signOutClickHandler}
                    onEditProfileClick={editProfileClickHandler}
                    lastClickedStatus={lastClickedStatus}
                    isLoadingUserStatus={
                      isFetchingUserStatus || updatingUserStatus
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </Toolbar>
      </AppBar>
    </AppBarContainer>
  );
};

const mapStateToProps = (state, ownProps) => ({
  GlobalData: state.GlobalSearch.GlobalSearchData,
  sequenceAdd: state.Workflow.sequenceAdd
});

export default connect(mapStateToProps)(PrimaryTopBar);
